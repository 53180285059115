@import '../../styles/customMediaQueries.css';

.langSwitchWrapper {
  position: relative;
  display: flex;
  align-items: center;
  align-content: center;
  height: 100%;
  margin: 20px 0;

  @media (--viewportLarge) {
    justify-content: center;
    margin: 5px 0 0;
  }
}

.langSwitchIcon {

  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 1;

  font-weight: var(--fontWeightRegular);
}

.flag {
  display: inline-flex;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  background-size: 20px;
  margin-right: 10px;

  &.en {
    background-image: url('./icons/united-kingdom.png');
  }

  &.fr {
    background-image: url('./icons/france.png');
  }

  &.de {
    background-image: url('./icons/germany.png');
  }

  &.it {
    background-image: url('./icons/italy.png');
  }

  &.es {
    background-image: url('./icons/spain.png');
  }
}

.icon {
  height: 20px;
  width: auto;
  margin: 0 auto;
  cursor: pointer;
}

.langSwitchLabel {
  display: flex;
  align-items: center;
  padding: 0;

  @media (--viewportLarge) {
    padding: 0 12px;
  }
}

.langSwitchContent {
  top: -14px;
  left: -9px !important;
  right: auto !important;

  min-width: 177px !important;
  max-width: 177px !important;
  width: 100%;
  border-top: none;
  box-shadow: 0 4px 4px #00000040 !important;

  @media (--viewportLarge) {
    left: 3px !important;
    top: -15px;
  }

  &>div {
    display: none;
  }

  & ul {
    padding: 14px 10px;
  }

  & li {
    margin-bottom: 12px;


    &:last-child {
      margin-bottom: 0;
    }
  }
}

.label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 1;

  margin: 0 auto;
  cursor: pointer;

  &:hover,
  &:focus {
    font-weight: var(--fontWeightSemiBold);
  }
}

.labelActive {
  font-weight: var(--fontWeightSemiBold);

  &::after {
    content: '\2713';
    display: inline-block;
    margin-left: 5px;
  }
}